import { createGenericAsyncThunk, createGenericSlice } from '../../generic';
import { FacilityEntity, GenericState, UniqueIdentifier } from '../../../types';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import { API_URL } from '../../../config';

export type CateringCompany = {
  id: number;
  name: string;
  phone: string;
  email: string;
  description: string;
};

export const createFacilityCateringCompany = createGenericAsyncThunk<CateringCompany, FacilityEntity<CateringCompany>>(
  'catering/createFacilityCateringCompany',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax['post'](`${API_URL}/api/v1/facilities/${facilityId}/catering`, value);

    const data: CateringCompany = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);

export const deleteFacilityCateringCompany = createGenericAsyncThunk<void, FacilityEntity<CateringCompany>>(
  'catering/deleteFacilityCateringCompany',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.delete(`${API_URL}/api/v1/facilities/${facilityId}/catering/${value.id}`);
    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, await response.json()));
    }
  }
);

export const updateFacilityCateringCompany = createGenericAsyncThunk<CateringCompany, FacilityEntity<CateringCompany>>(
  'catering/updateFacilityCateringCompany',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax['put'](`${API_URL}/api/v1/facilities/${facilityId}/catering/${value.id}`, value);

    const data: CateringCompany = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);
export const fetchFacilityCateringCompanies = createGenericAsyncThunk<CateringCompany[], UniqueIdentifier>(
  'catering/fetchFacilityCateringCompanys',
  async (facilityId, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(`${API_URL}/api/v1/facilities/${facilityId}/catering`);
    const data: CateringCompany[] = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);

const cateringSlice = createGenericSlice({
  name: 'cateringSlice',
  initialState: {
    status: 'idle',
    data: [],
    loaded: false
  } as GenericState<CateringCompany[]>,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFacilityCateringCompanies.fulfilled, (state, action) => {
      /*
       * Override default behaviour
       */

      state.loaded = true;
      state.status = 'succeeded';
      // @ts-ignore
      state.data = action.payload;
      delete state.error;
    });
  }
});

export const { start, succeeded, failed } = cateringSlice.actions;

export const selectCateringCompanies = createSelector([(state: RootState) => state.catering], (catering) => catering.data);

export const cateringReducer = cateringSlice.reducer;
