import {getToken, isSupported, getMessaging, onMessage} from "firebase/messaging";
import {initializeApp} from "firebase/app";
import {updateUserToken} from "./store/feature/user";
import firebase from "firebase/compat";
import { RootDispatch } from './store';
import {Messaging} from "@firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyAQQQ7aiqogxMSeKzH2s9aUVuKFm0jJ40Q",
    authDomain: "school-9ac89.firebaseapp.com",
    projectId: "school-9ac89",
    storageBucket: "school-9ac89.appspot.com",
    messagingSenderId: "282979171091",
    appId: "1:282979171091:web:e08f07d853dbfa2ad2b8e6",
    measurementId: "G-LL01FRZ9SC"
};

const firebaseApp = initializeApp(firebaseConfig);


const initFireBase = (dispatch: RootDispatch, onMessageHandler: (payload: firebase.messaging.MessagePayload) => void) => {
    const messaging = getMessaging(firebaseApp);
    requestToken(messaging, dispatch, onMessageHandler);
}

const requestToken = (messaging:Messaging, dispatch: RootDispatch, onMessageHandler: (payload: firebase.messaging.MessagePayload) => void) => {
    getToken(messaging, { vapidKey: "BMqCsX6vSLTZN6b56HptX5lkRJWYKABsXEXjXmcg3TtQNT2zbl_McgLaemNt6mnzg2ee830vJpRq2B_znUqYR-A"}).then((currentToken) => {
        if (currentToken) {
            if (localStorage.getItem('firebase.token') !== currentToken) {
                dispatch(updateUserToken({token: currentToken})).then(() => {
                    localStorage.setItem('firebase.token', currentToken);
                    console.log('Token updated', currentToken);
                })
            }
            onMessage(messaging, onMessageHandler)
        }
    }).catch((err) => {
        setTimeout(() => {
            const retryStr = sessionStorage.getItem('token.retry');
            let retry = retryStr ? Number(retryStr) : 0;
            if (retry < 3) {
                requestToken(messaging, dispatch, onMessageHandler);
                sessionStorage.setItem('token.retry', String(++retry))
            } else {
                console.log('An error occurred while retrieving token. ', err);
                alert("An error occurred while enabling notifications!")
            }
        }, 500)
    });
}

export const initMessaging = (dispatch: RootDispatch, onMessageHandler: (payload: firebase.messaging.MessagePayload) => void) => {

    isSupported().then((isSupported) => {
        if (!isSupported || !("Notification" in window)) {
            console.log('Push messaging is not supported');
            return;
        }

        if (Notification.permission === "granted") {
            initFireBase(dispatch, onMessageHandler)
        } else if(Notification.permission !== "denied") {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    initFireBase(dispatch, onMessageHandler)
                }
            });
        }
    })
}

