// material-ui
import { styled, useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@mui/material';

import { UserNotification } from '../../../../store/feature/user';
import React from 'react';
import { useNavigate } from 'react-router';
import {prettyDate} from "../../../../utils/misc";

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  padding: 16,
  '&:hover': {
    background: theme.palette.primary.light
  },
  '& .MuiListItem-root': {
    padding: 0
  }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //
type Props = {
  notifications: UserNotification
  close: () => void
}
const NotificationList = ({notifications, close} : Props) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const handleChatClick = (chatId: number) => {
    navigate(`/chat/${chatId}`)
    close();
  }

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 330,
        py: 0,
        borderRadius: '10px',
        [theme.breakpoints.down('md')]: {
          maxWidth: 300,
          maxHeight: 500,
          overflow: 'auto'
        },
        '& .MuiListItemSecondaryAction-root': {
          top: 22
        },
        '& .MuiDivider-root': {
          my: 0
        },
        '& .list-container': {
          pl: 7
        }
      }}
    >
      { notifications.chat.map((chatNotification, index) =>
        <Box key={index}>
        <ListItemWrapper onClick={() => handleChatClick(chatNotification.chatId)}>
          <ListItem alignItems="center">
            <ListItemAvatar>
              <Avatar alt={chatNotification.chatName} variant={"square"}/>
            </ListItemAvatar>
            <ListItemText primary={chatNotification.chatName} />
            <ListItemSecondaryAction>
              <Grid container justifyContent="flex-end">
                <Grid item xs={12}>
                  <Typography variant="caption" display="block" gutterBottom>
                    {prettyDate(chatNotification.time)}
                  </Typography>
                </Grid>
              </Grid>
            </ListItemSecondaryAction>
          </ListItem>
          <Grid container direction="column" className="list-container">
            <Grid item xs={12} sx={{ pb: 2 }}>
              <Typography variant="subtitle2">You have a new chat message. Click here to read it.</Typography>
            </Grid>
          </Grid>
        </ListItemWrapper>
        <Divider />
        </Box>
       )}
    </List>
  );
};

export default NotificationList;
