import { buildSlice, createGenericAsyncThunk, createGenericSlice } from "../../generic";
import { FacilityEntity, GenericState } from "../../../types";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { StudentType } from "../studentType";
import { FacilityGroup } from "../facilityGroup";
import { ExtraClassGroup } from "../extraClassGroup";
import { API_URL } from '../../../config';

export type StudentParentView = {
  studentId: number,
  studentFullName: string,
  parentsFullName: string[]
}

export type StudentBasicView = {
  id: number;
  fullName: string;
};

export type StudentListView = {
  id: number;
  fullName: string;
  birthDate: string;
  startDate: string;
  studentTypeName: string;
  facilityGroupName: string | undefined;
};

export type Student = {
  id: number;
  firstName: string;
  avatar: string;
  familyName: string;
  birthDate: string;
  startDate: string;
  parents: object[];
  facilityGroup: FacilityGroup | undefined;
  studentType: StudentType;
  extraClasses: ExtraClassGroup[];
  createdAt: string;
  updatedAt: string;
};

export const createFacilityStudent = createGenericAsyncThunk<Student, FacilityEntity<Student>>(
  'students/createFacilityStudent',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax['post'](`${API_URL}/api/v1/facilities/${facilityId}/students`, value);

    const data: Student = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);

export const deleteFacilityStudent = createGenericAsyncThunk<void, FacilityEntity<Student>>(
  'students/deleteFacilityStudent',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.delete(`${API_URL}/api/v1/facilities/${facilityId}/students/${value.id}`);
    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, await response.json()));
    }
  }
);

export const updateFacilityStudent = createGenericAsyncThunk<Student, FacilityEntity<Student>>(
  'students/updateFacilityStudent',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax['put'](`${API_URL}/api/v1/facilities/${facilityId}/students/${value.id}`, value);

    const data: Student = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);

export type FacilitySearchFilter = {
  facilityId: number;
  groupId?: number;
};
export const fetchFacilityBasicStudents = createGenericAsyncThunk<StudentBasicView[], FacilitySearchFilter>(
  'students/fetchFacilityBasicStudents',
  async ({ facilityId, groupId }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(
      `${API_URL}/api/v1/facilities/${facilityId}/students/basic${groupId ? `?group-id=${groupId}` : ''}`
    );
    const data: StudentBasicView[] = await response.json();
    data.sort((a, b) => a.fullName.localeCompare(b.fullName))

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }
    return data;
  }
);
export const fetchFacilityStudents = createGenericAsyncThunk<StudentListView[], {facilityId: number, parentId?: number}>(
  'students/fetchFacilityStudents',
  async ({ facilityId, parentId }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(`${API_URL}/api/v1/facilities/${facilityId}/students${parentId ? `?parent-id=${parentId}` : ''}`);
    const data: StudentListView[] = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }
    return data;
  }
);

export const fetchFacilityStudent = createGenericAsyncThunk<Student, FacilityEntity<number>>(
  'students/fetchFacilityStudent',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(`${API_URL}/api/v1/facilities/${facilityId}/students/${value}`);
    const data: Student = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }
    return data;
  }
);

export const fetchFacilityStudentParents = createGenericAsyncThunk<StudentParentView[], { facilityId: number; facilityGroups: number[] }>(
  'students/fetchFacilityStudentParents',
  async ({ facilityId, facilityGroups }, { extra, rejectWithValue }) => {
    const params = facilityGroups && facilityGroups.length > 0 ? `?${facilityGroups.map((f) => `groups=${f}`).join('&')}` : '';
    const response = await extra.ajax.get(`${API_URL}/api/v1/facilities/${facilityId}/students/view/parents${params}`);
    const data: StudentParentView[] = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }
    return data;
  }
);

const studentParentsSlice = buildSlice<StudentParentView[], { facilityId: number; facilityGroups: number[] }>(
  'studentParentsSlice',
  fetchFacilityStudentParents.fulfilled
);

const studentsBasicSlice = createGenericSlice({
  name: 'studentsBasicSlice',
  initialState: {
    status: 'idle',
    data: [],
    loaded: false
  } as GenericState<StudentBasicView[]>,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFacilityBasicStudents.fulfilled, (state, action) => {
      state.loaded = true;
      state.status = 'succeeded';
      // @ts-ignore
      state.data = action.payload;
      delete state.error;
    });
  }
});


const studentsSlice = buildSlice<StudentListView[], {facilityId: number, parentId?: number}>(
    'calendarDatesSlice',
    fetchFacilityStudents.fulfilled
);

const studentSlice = createGenericSlice({
  name: 'studentSlice',
  initialState: {
    status: 'idle',
    data: undefined,
    loaded: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFacilityStudent.fulfilled, (state, action) => {
      state.loaded = true;
      state.status = 'succeeded';
      // @ts-ignore
      state.data = action.payload;
      delete state.error;
    });
  }
});

export const { start, succeeded, failed } = studentSlice.actions;

export const selectStudents = createSelector([(state: RootState) => state.students], (students) => students.data);
export const selectStudent = createSelector([(state: RootState) => state.student], (student) => student.data);
export const selectBasicStudents = createSelector([(state: RootState) => state.studentsBasic], (students) => students.data);
export const selectStudentsParents = createSelector([(state: RootState) => state.studentsParents], (students) => students.data);

export const studentsReducer = studentsSlice.reducer;
export const studentReducer = studentSlice.reducer;
export const studentsBasicReducer = studentsBasicSlice.reducer;
export const studentParentsReducer = studentParentsSlice.reducer;
