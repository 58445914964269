// material-ui

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  return <img src="https://compass.edu.pl/wp-content/uploads/2023/10/cropped-compass-logo-new-1-1-310x99.png" alt="Compass" width="170" />;
};

export default Logo;
