import {fetchAuthSession} from 'aws-amplify/auth';

export type FetchType = (path: string, requestConfig?: RequestInit) => Promise<Response>;

export type FetchWithBodyType = (path: string, body?: object | undefined, requestConfig?: RequestInit) => Promise<Response>;
export type FetchUploadFile = (path: string, body: FormData, requestConfig?: RequestInit) => Promise<Response>;

export type AjaxServiceType = {
  fetch: FetchType;
  get: FetchType;
  post: FetchWithBodyType;
  postFile: FetchUploadFile;
  put: FetchWithBodyType;
  patch: FetchWithBodyType;
  delete: FetchType;
};

export const siteTypeRegExp = /(\/admin)?\/((course|event)s)\//;

const AjaxService: AjaxServiceType = {
  fetch(path, config) {
    const request = new Request(path, config);

    if (!request.headers.get('Accept-Language')) {
      request.headers.set('Accept-Language', 'en');
    }

    if (typeof config?.body === 'string') {
      request.headers.set('Content-Type', 'application/json');
    }

    return fetchAuthSession().then((session) => {
      request.headers.set('Authorisation', session?.tokens?.idToken?.toString() ?? '');
      return window.fetch(request).then((response) => {
        return response;
      });
    })
  },
  get(path, requestConfig) {
    return this.fetch(path, { method: 'GET', ...requestConfig });
  },
  postFile(path, body: FormData, requestConfig) {
    return this.fetch(path, {
      method: 'POST',
      body,
      ...requestConfig
    });
  },
  post(path, body, requestConfig) {
    return this.fetch(path, {
      method: 'POST',
      body: JSON.stringify(body),
      ...requestConfig
    });
  },
  patch(path, body, requestConfig) {
    return this.fetch(path, {
      method: 'PATCH',
      body: JSON.stringify(body),
      ...requestConfig
    });
  },
  put(path, body, requestConfig) {
    return this.fetch(path, {
      method: 'PUT',
      body: JSON.stringify(body),
      ...requestConfig
    });
  },
  delete(path, requestConfig) {
    return this.fetch(path, { method: 'DELETE', ...requestConfig });
  }
};

export default AjaxService;
