import {buildSlice, createGenericAsyncThunk} from '../../generic';
import {createSelector} from '@reduxjs/toolkit';
import {RootState} from '../../index';
import {API_URL} from '../../../config';
import {getMobileOperatingSystem, isStandaloneMobileApp} from "../../../utils/misc";

export type UserNotification = {
  chat: {chatId: number, chatName : string, time: string}[]
}

export type UserPreferences = {
  enabledChatNotifications: boolean
}

const updateAppIfNeeded = (response: Response) => {

  const existingVersion = localStorage.getItem('compass.version');
  const newVersion = response.headers.get('X-Compass-Version');
  if (newVersion) {
    if (!existingVersion) {
        console.log("UPDATING")
      localStorage.setItem('compass.version', newVersion);
      setTimeout(() => {
          window.location.reload();
      }, 500)
    } else if(existingVersion !== newVersion) {
        console.log("UPDATING")
      if ('serviceWorker' in navigator) {
        caches.keys().then(function(cacheNames) {
          cacheNames.forEach(function(cacheName) {
            caches.delete(cacheName).then(r => console.log('Removed App cache ', r));
          });
        });
      }
      localStorage.setItem('compass.version', newVersion);
      console.log('Updating APP');
      window.location.reload();
    }
  }
}

export const updateUserToken = createGenericAsyncThunk<void, { token: string }>(
  'user/updateUserToken',
  async ({token}, {extra, rejectWithValue}) => {
    const response = await extra.ajax['post'](`${API_URL}/api/public/v1/user/notification-token`, { token, os: getMobileOperatingSystem(), ua: window.navigator.userAgent, pwa: isStandaloneMobileApp()});
    const data = await response.json();
    if (response.status !== 200) {
      return rejectWithValue({statusCode: response.status, response: data});
    }
  }
)

export const fetchUserNotifications = createGenericAsyncThunk<UserNotification, void>(
  'user/fetchNotifications',
  async (_a, {extra, rejectWithValue}) => {
    const response = await extra.ajax['get'](`${API_URL}/api/v1/user/notifications`);
    const data = await response.json() as UserNotification;
    if (response.status !== 200) {
      return rejectWithValue({statusCode: response.status, response: data});
    }
    try {
      if (window.navigator && window.navigator.setAppBadge && window.navigator.clearAppBadge) {
        if (data.chat.length === 0) {
          console.log("REMOVE BADGE")
          window.navigator.clearAppBadge()
        } else {
          console.log("SET BADGE")
          window.navigator.setAppBadge(data.chat.length)
        }
      }
    } catch (e) {
      console.log(e);
    }

    updateAppIfNeeded(response);
    return data;
  }
)

export const fetchUserPreferences = createGenericAsyncThunk<UserPreferences, void>(
  'user/fetchPreferences',
  async (_a, {extra, rejectWithValue}) => {
    const response = await extra.ajax['get'](`${API_URL}/api/v1/user/settings`);
    const data = await response.json() as UserPreferences;
    if (response.status !== 200) {
      return rejectWithValue({statusCode: response.status, response: data});
    }
    return data;
  }
)

export const updateUserPreferences = createGenericAsyncThunk<void, UserPreferences>(
  'user/updateUserPreferences',
  async (userPref, {extra, rejectWithValue}) => {
    const response = await extra.ajax['put'](`${API_URL}/api/v1/user/settings`, userPref);
    const data = await response.json();
    if (response.status !== 200) {
      return rejectWithValue({statusCode: response.status, response: data});
    }
  }
)

const fetchUserNotificationsSlice = buildSlice<UserNotification, void>(
  'fetchNotificationsSlice', fetchUserNotifications.fulfilled
);
export const userNotificationsReducer = fetchUserNotificationsSlice.reducer;
export const selectUserNotifications = createSelector([(state: RootState) => state.userNotifications], (notifications) => notifications.data);


const fetchUserPreferencesSlice = buildSlice<UserPreferences, void>(
  'fetchPreferencesSlice', fetchUserPreferences.fulfilled
);
export const userPreferencesReducer = fetchUserPreferencesSlice.reducer;
export const selectUserPreferences = createSelector([(state: RootState) => state.userPreferences], (pref) => pref.data);
