import { createGenericAsyncThunk, createGenericSlice } from '../../generic';
import { ParentEntity, GenericState, UniqueIdentifier } from '../../../types';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import { Student } from '../student';
import dayjs from 'dayjs';
import { API_URL } from '../../../config';

export type ExtraClassGroup = {
  id: number;
  students: Student[];
  priceBy: 'LESSON' | 'MONTH' | 'PERIOD';
  startDate: string | dayjs.Dayjs;
  endDate: string | dayjs.Dayjs;
  days: string[];
  cancelable: boolean;
  frequency: 'WEEKLY' | 'BIWEEKLY' | 'MONTHLY' | 'CUSTOM';
  time: string | dayjs.Dayjs;
  durationMin: string;
  maxNumberOfStudents: number;
  price: string;
};

export const createFacilityExtraClassGroup = createGenericAsyncThunk<ExtraClassGroup, ParentEntity<ExtraClassGroup>>(
  'extraClassGroups/createFacilityExtraClassGroup',
  async ({ parentId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax['post'](`${API_URL}/api/v1/extra-classes/${parentId}/groups`, value);

    const data: ExtraClassGroup = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);

export const deleteFacilityExtraClassGroup = createGenericAsyncThunk<void, ParentEntity<ExtraClassGroup>>(
  'extraClassGroups/deleteFacilityExtraClassGroup',
  async ({ parentId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.delete(`${API_URL}/api/v1/extra-classes/${parentId}/groups/${value.id}`);
    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, await response.json()));
    }
  }
);

export const updateFacilityExtraClassGroup = createGenericAsyncThunk<ExtraClassGroup, ParentEntity<ExtraClassGroup>>(
  'extraClassGroups/updateFacilityExtraClassGroup',
  async ({ parentId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax['put'](`${API_URL}/api/v1/extra-classes/${parentId}/groups/${value.id}`, value);

    const data: ExtraClassGroup = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);
export const fetchFacilityExtraClassGroups = createGenericAsyncThunk<ExtraClassGroup[], UniqueIdentifier>(
  'extraClassGroups/fetchFacilityExtraClassGroups',
  async (groupId, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(`${API_URL}/api/v1/extra-classes/${groupId}/groups`);
    const data: ExtraClassGroup[] = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);

const extraClassGroupSlice = createGenericSlice({
  name: 'extraClassGroupsSlice',
  initialState: {
    status: 'idle',
    data: [],
    loaded: false
  } as GenericState<ExtraClassGroup[]>,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFacilityExtraClassGroups.fulfilled, (state, action) => {
      /*
       * Override default behaviour
       */

      state.loaded = true;
      state.status = 'succeeded';
      // @ts-ignore
      state.data = action.payload;
      delete state.error;
    });
  }
});

export const { start, succeeded, failed } = extraClassGroupSlice.actions;

export const selectExtraClassGroups = createSelector(
  [(state: RootState) => state.extraClassGroup],
  (extraClassGroups) => extraClassGroups.data
);

export const extraClassGroupReducer = extraClassGroupSlice.reducer;
