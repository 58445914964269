import {
  createSlice,
  ActionReducerMapBuilder,
  AsyncThunk,
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
  AsyncThunkPayloadCreator,
  createAsyncThunk,
  Dispatch,
  AsyncThunkOptions,
  CaseReducer
} from '@reduxjs/toolkit';
import { AjaxServiceType } from '../../services/Ajax';
import { GenericState, RejectedPayloadData } from '../../types';
import { AsyncThunkFulfilledActionCreator } from '@reduxjs/toolkit/dist/createAsyncThunk';

export type ProcessRejectedValue = (response: Response, data: unknown) => RejectedPayloadData<typeof data>;

export type AsyncThunkConfig = {
  dispatch: Dispatch;
  state: unknown;
  extra: {
    ajax: AjaxServiceType;
    processRejectedValue: ProcessRejectedValue;
  };
  rejectValue: RejectedPayloadData<unknown>;
};

export const createGenericSlice = <T, Reducers extends SliceCaseReducers<GenericState<T>>>({
  name,
  initialState,
  reducers,
  extraReducers
}: {
  name: string;
  initialState: GenericState<T>;
  reducers: ValidateSliceCaseReducers<GenericState<T>, Reducers>;
  extraReducers?: (builder: ActionReducerMapBuilder<GenericState<T>>) => void;
}) =>
  createSlice({
    name,
    initialState,
    reducers: {
      start(state) {
        state.status = 'pending';
      },
      succeeded(state: GenericState<T>, action: PayloadAction<T>) {
        state.loaded = true;
        state.status = 'succeeded';
        state.data = action.payload;
        delete state.error;
      },
      failed(state: GenericState<T>, action: PayloadAction<unknown>) {
        state.loaded = false;
        state.status = 'failed';
        state.error = action.payload;
      },
      reset(state: GenericState<T>) {
        state.status = initialState.status;
        state.data = initialState.data;
        state.loaded = initialState.loaded;
        delete state.error;
      },
      ...reducers
    },
    extraReducers
  });
export const buildArrSlice = <Returned, Arg>(name: string, creator: AsyncThunkFulfilledActionCreator<Returned | Promise<Returned>, Arg>) => {
  return createGenericSlice({
    name,
    initialState: {
      status: 'idle',
      loaded: false,
      data: [] as Returned
    } as GenericState<Returned>,
    reducers: {},
    extraReducers: (builder) => {

      builder.addCase(creator, (state, action) => {
        state.loaded = true;
        state.status = 'succeeded';
        // @ts-ignore
        state.data = action.payload;
        delete state.error;
      });
    }
  });
};
export const buildCustomSlice = <Returned>(name: string, extraReducers: (builder: ActionReducerMapBuilder<GenericState<Returned>>) => void) => {
  return createGenericSlice({
    name,
    initialState: {
      status: 'idle',
      loaded: false,
      data:  undefined
    } as GenericState<Returned>,
    reducers: {},
    extraReducers
  });
};

export const buildSlice = <Returned, Arg>(name: string, creator: AsyncThunkFulfilledActionCreator<Returned | Promise<Returned>, Arg>) => {
  return createGenericSlice({
    name,
    initialState: {
      status: 'idle',
      loaded: false,
      data:  undefined
    } as GenericState<Returned>,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(creator, (state, action) => {
        state.loaded = true;
        state.status = 'succeeded';
        //@ts-ignore
        state.data = action.payload;
        delete state.error;
      });
    }
  });
};

export const createGenericBuilderCases = <T = void>(
  builder: ActionReducerMapBuilder<GenericState<T>>,
  asyncThunk: AsyncThunk<any, any, any>,
  succeeded?: CaseReducer<GenericState<T>>
) => {
  builder.addCase(asyncThunk.pending, (state) => {
    state.status = 'pending';
  });
  builder.addCase(asyncThunk.fulfilled, (state, action) => {
    /*
     * Override default behaviour
     */
    if (succeeded) {
      succeeded(state, action);
      return;
    }

    state.loaded = true;
    state.status = 'succeeded';
    // @ts-ignore
    state.data = action.payload;
    delete state.error;
  });
  builder.addCase(asyncThunk.rejected, (state, { payload }) => {
    state.status = 'failed';
    state.error = payload;
  });
};

export function createGenericAsyncThunk<Returned, ThunkArg = void>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, AsyncThunkConfig>,
  options?: AsyncThunkOptions<ThunkArg, AsyncThunkConfig>
) {
  return createAsyncThunk(
    typePrefix,
    async (arg, thunkAPI) => {
      try {
        return await payloadCreator(arg, thunkAPI);
      } catch (err) {
        return thunkAPI.rejectWithValue({
          response: {
            message: 'Connection error'
          },
          statusCode: 'CONNECTION_ERROR',
          // @ts-ignore
          aborted: err?.name === 'AbortError'
        });
      }
    },
    options
  );
}

export const processRejectedValue: ProcessRejectedValue = (response, data) => {
  return {
    response: data,
    statusCode: response.status
  };
};
