import { useSelector } from 'react-redux';

import { Amplify } from 'aws-amplify';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import './ui-component/widget/login/index.css';

// routing
import Routes from './routes';

// defaultTheme
import themes from './themes';

// project imports
import NavigationScroll from './layout/NavigationScroll';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isToday from 'dayjs/plugin/isToday';
import updateLocale from 'dayjs/plugin/updateLocale';
import { useEffect, useState } from 'react';
import { Authenticator } from '@aws-amplify/ui-react';
import { useRootDispatch } from './hooks';
import { SET_USER_ID, SET_USER_NAME, SET_USER_ROLE } from './store/configurationReducer';
import { SignInHeader } from './ui-component/widget/login/SignInHeader';
import { initMessaging } from './firebase';
import { fetchUserNotifications } from './store/feature/user';
import { Loader } from './ui-component/Loader';
import { SET_APP_VISIBLE } from './store/miscReducer';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

// ==============================|| APP ||============================== //
dayjs.extend(utc);
dayjs.extend(updateLocale);
dayjs.extend(timezone);
dayjs.extend(isToday);
dayjs.updateLocale('en', {
  weekStart: 1
});
const App = () => {
  // @ts-ignore
  const customization = useSelector((state) => state.customization);
  const dispatch = useRootDispatch();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);

  const fetchUser = () => {
    fetchUserAttributes().then((attributes) => {
      dispatch({ type: SET_USER_ID, value: attributes['sub']});
      dispatch({ type: SET_USER_NAME, value: attributes.given_name + ' ' + attributes.family_name});
      dispatch({ type: SET_USER_ROLE, value: attributes['custom:role']});
      setIsUserLoggedIn(true);
    }).catch( (error) => {
      console.log(error);
      localStorage.clear();
    })
  }

  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolClientId: '66mth4h956gf04ku5gap3rbfoj',
        userPoolId: 'eu-north-1_F29SLEncR',
      }
    }
  });

  useEffect(() => {
    if(isUserLoggedIn) {
      initMessaging(dispatch, (payload) => {
        console.log(payload);
        dispatch(fetchUserNotifications());
      });
      window.addEventListener("visibilitychange", function () {
        dispatch({type: SET_APP_VISIBLE, value: document.visibilityState === "visible"})
        if (document.visibilityState === "visible") {
          console.log("APP resumed");
          dispatch(fetchUserNotifications());
        }
      });
    }

  }, [isUserLoggedIn]);

  return (
    <Authenticator hideSignUp={true}  components={{SignIn: {Header: SignInHeader}}}>
      {() => {

        if (isUserLoggedIn) {
          return (
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <DndProvider backend={HTML5Backend}>
                  <NavigationScroll>
                    <Routes />
                  </NavigationScroll>
                </DndProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          )
        } else {
          fetchUser();
          return <Loader/>
        }

      }}
    </Authenticator>
  );
};

export default App;
