import { Avatar, Badge, Box, ButtonBase } from '@mui/material';
import {  IconReload } from '@tabler/icons-react';
import { useTheme } from '@mui/material/styles';

export const Reload = () => {

  const theme = useTheme();

  return <Box
    sx={{
      ml: 2,
      mr: 3,
      [theme.breakpoints.down('md')]: {
        mr: 2
      }
    }}
  >
    <ButtonBase sx={{ borderRadius: '12px' }}>
      <Avatar
        variant="rounded"
        sx={{
          transition: 'all .2s ease-in-out',
          background: theme.palette.secondary.light,
          color: theme.palette.secondary.dark,
          '&[aria-controls="menu-list-grow"],&:hover': {
            background: theme.palette.secondary.dark,
            color: theme.palette.secondary.light
          }
        }}
        onClick={() => window.location.reload()}
        color="inherit"
      >
        <IconReload stroke={1.5}  />
      </Avatar>
    </ButtonBase>
  </Box>
}

