import {combineReducers} from 'redux';

import customizationReducer from './customizationReducer';
import {studentTypeReducer} from './feature/studentType';
import {formDataReducer} from './feature/formData';
import {holidayReducer, simpleHolidayReducer} from './feature/holiday';
import {facilityGroupReducer} from './feature/facilityGroup';
import {studentParentsReducer, studentReducer, studentsBasicReducer, studentsReducer} from "./feature/student";
import {cateringReducer} from './feature/catering';
import {mealReducer} from './feature/meal';
import {extraClassesReducer, extraClassReducer} from './feature/extraClass';
import {extraClassGroupReducer} from './feature/extraClassGroup';
import {calendarExtraGroupDatesReducer} from './feature/calendar';
import {parentReducer, parentsReducer} from "./feature/parents";
import {eventGalleriesReducer, eventMessagesReducer} from './feature/messages';
import {chatsReducer, userChatMessagesReducer} from './feature/chat';
import {configurationReducer} from "./configurationReducer";
import {usersReducer} from "./feature/users";
import {invoicesReducer} from "./feature/invoices";
import {userNotificationsReducer, userPreferencesReducer} from './feature/user';
import {siteEventsReducer} from './siteEventsReducer';
import {miscReducer} from './miscReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  formData: formDataReducer,
  customization: customizationReducer,
  configuration: configurationReducer,
  studentTypes: studentTypeReducer,
  extraClass: extraClassReducer,
  extraClassGroup: extraClassGroupReducer,
  extraClasses: extraClassesReducer,
  meals: mealReducer,
  catering: cateringReducer,
  facilityGroups: facilityGroupReducer,
  students: studentsReducer,
  parents: parentsReducer,
  parent: parentReducer,
  calendarExtraGroup: calendarExtraGroupDatesReducer,
  studentsBasic: studentsBasicReducer,
  studentsParents: studentParentsReducer,
  student: studentReducer,
  holidays: holidayReducer,
  invoices: invoicesReducer,
  chats: chatsReducer,
  users: usersReducer,
  chatMessages: userChatMessagesReducer,
  eventMessages: eventMessagesReducer,
  eventGalleries: eventGalleriesReducer,
  userNotifications: userNotificationsReducer,
  userPreferences: userPreferencesReducer,
  siteEvents: siteEventsReducer,
  simpleHolidays: simpleHolidayReducer,
  misc: miscReducer
});

export default reducer;
