import { useRootDispatch, useRootSelector } from '../index';
import { useEffect } from 'react';
import { fetchUserNotifications, selectUserNotifications } from '../../store/feature/user';

export function useFetchUserNotifications() {
  const dispatch = useRootDispatch();

  useEffect(() => {
    dispatch(fetchUserNotifications());
  }, [dispatch]);

  return useRootSelector((state) => selectUserNotifications(state));
}
