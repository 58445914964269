// assets
import {
  IconBottle,
  IconBrandChrome,
  IconBrandSuperhuman,
  IconHelp,
  IconMessage,
  IconSchool,
  IconMessage2,
  IconBeach,
  IconCalendar,
  IconFileInvoice,
  IconPictureInPicture,
  IconPhoto
} from "@tabler/icons-react";
import { MenuItem } from '../types';

// constant
const icons = { IconSchool, IconCalendar, IconFileInvoice, IconBeach, IconMessage, IconMessage2, IconBrandChrome, IconHelp, IconBottle, IconBrandSuperhuman, IconPictureInPicture };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const school = {
  id: 'school',
  title: 'School',
  type: 'group',
  children: [
    {
      id: 'messages',
      title: 'Announcements',
      type: 'item',
      url: '/messages',
      icon: IconMessage2,
      breadcrumbs: false,
      roles: ['OWNER', 'TEACHER']
    },
    {
      id: 'gallery',
      title: 'Gallery',
      type: 'item',
      url: '/galleries',
      icon: IconPhoto,
      breadcrumbs: false,
      roles: ['OWNER', 'TEACHER']
    },
    {
      id: 'chat',
      title: 'Chat',
      type: 'item',
      url: '/chat',
      icon: IconMessage,
      breadcrumbs: false,
      notification: 'chat',
      roles: ['OWNER', 'TEACHER']
    },
    {
      id: 'calendar',
      title: 'Calendar',
      type: 'item',
      url: '/calendar',
      icon: icons.IconCalendar,
      breadcrumbs: false,
      roles: ['OWNER', 'TEACHER']
    },
    {
      id: 'extra-classes',
      title: 'Extra classes',
      type: 'item',
      url: '/extra-classes',
      icon: icons.IconSchool,
      breadcrumbs: false,
      roles: ['OWNER']
    },
    {
      id: 'holiday',
      title: 'Holidays',
      type: 'item',
      url: '/holidays',
      icon: icons.IconBeach,
      breadcrumbs: false,
      roles: ['OWNER', 'TEACHER']
    },
    {
      id: 'invoices',
      title: 'Invoices',
      type: 'item',
      url: '/invoices',
      icon: icons.IconFileInvoice,
      breadcrumbs: false,
      roles: ['OWNER']
    }
  ]
} as MenuItem

export default school;
