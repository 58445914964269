import { useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  FormControlLabel,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { signOut } from 'aws-amplify/auth';

// project imports
import MainCard from '../../../../ui-component/cards/MainCard';
import Transitions from '../../../../ui-component/extended/Transitions';

// assets
import { IconLogout, IconSettings } from '@tabler/icons-react';
import { useRootDispatch, useRootSelector } from '../../../../hooks';
import { IOSSwitch } from '../../../../ui-component/widget/switcher';
import { useFetchUserPreferences } from '../../../../hooks/fetch/useFetchUserPreferences';
import { fetchUserPreferences, updateUserPreferences } from '../../../../store/feature/user';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
  const theme = useTheme();
  const {userFullName, userRole} = useRootSelector((s) => s.configuration)
  const userPreferences = useFetchUserPreferences();
  const dispatch = useRootDispatch();

  const [selectedIndex, setSelectedIndex] = useState(-1);
  const [open, setOpen] = useState(false);
  /**
   * anchorRef is used on different componets and specifying one type leads to other components throwing an error
   * */
  const anchorRef = useRef(null);
  const handleLogout = async () => {
    signOut()
      .then(() => localStorage.clear())
      .catch(err => console.log(err));
  };

  const updatePreferences = (v: { target: { checked: any; }; }) => {
    dispatch(updateUserPreferences({enabledChatNotifications: v.target.checked}))
      .then(() => dispatch(fetchUserPreferences()))
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Chip
        sx={{
          height: '48px',
          alignItems: 'center',
          borderRadius: '27px',
          transition: 'all .2s ease-in-out',
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            '& svg': {
              stroke: theme.palette.primary.light
            }
          },
          '& .MuiChip-label': {
            lineHeight: 0
          }
        }}

        label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />


            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <Popper
                  placement="bottom-end"
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  popperOptions={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, 14]
                        }
                      }
                    ]
                  }}
                >
                  {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                  <Box sx={{ p: 2 }}>
                    <Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        <Typography variant="h4">Good Morning,</Typography>
                        <Typography component="span" variant="h4" sx={{ fontWeight: 400 }}>
                          {userFullName}
                        </Typography>
                      </Stack>
                      <Typography variant="subtitle2" style={{textTransform:'capitalize'}}>{userRole.toLowerCase()}</Typography>
                    </Stack>
                    <Divider />
                    <Box>
                      <Typography variant="subtitle1" sx={{ fontWeight: 600, marginTop: '20px' }}>Preferences:</Typography>
                      <Box sx={{ padding: '10px' }}>
                        <FormControlLabel
                          control={ <IOSSwitch name={"invoice"}  checked={userPreferences && userPreferences.enabledChatNotifications} onChange={updatePreferences}/>}
                          label="Chat notifications"
                          sx={{'& .MuiFormControlLabel-label': {ml: 2}}}
                        />
                      </Box>
                      <Divider sx={{m:1}} />
                  </Box>
                    <Box >
                      <List
                        component="nav"
                        sx={{
                          width: '100%',
                          maxWidth: 350,
                          minWidth: 300,
                          backgroundColor: theme.palette.background.paper,
                          borderRadius: '10px',
                          [theme.breakpoints.down('md')]: {
                            minWidth: '100%'
                          },
                          '& .MuiListItemButton-root': {
                            mt: 0.5
                          }
                        }}
                      >

                        <ListItemButton
                          sx={{ borderRadius: `12px`, p: 0, m: 0 }}
                          selected={selectedIndex === 4}
                          onClick={handleLogout}
                        >
                          <ListItemIcon>
                            <IconLogout stroke={1.5} size="1.3rem" />
                          </ListItemIcon>
                          <ListItemText primary={<Typography variant="body2">Logout</Typography>} />
                        </ListItemButton>
                      </List>
                    </Box>
                  </Box>
                </MainCard>
                    </Transitions>
                  )}
                </Popper>
              </ClickAwayListener>
            </Paper>


    </>
  );
};

export default ProfileSection;
