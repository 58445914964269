import {Reducer} from "redux";

export const SET_USER_ID = '@configuration/SET_USER_ID';
export const SET_USER_NAME = '@configuration/SET_USER_NAME';
export const SET_USER_ROLE = '@configuration/SET_USER_ROLE';



export type ConfigurationAction = {
    type: typeof SET_USER_ROLE | typeof SET_USER_ID | typeof SET_USER_NAME ;
    value: number | string | number[]
}

export type ConfigurationState = {
    userId: string,
    userFullName: string,
    userRole: string,
}
export const initialState = {
  userId: '',
  userFullName: '',
  userRole: '',
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

export const configurationReducer : Reducer<ConfigurationState, ConfigurationAction> = (state = initialState, action: ConfigurationAction): ConfigurationState => {

    switch (action.type) {
    case SET_USER_ROLE:
      return {
        ...state,
        userRole: action.value as string
      };
    case SET_USER_ID:
        return {
            ...state,
            userId: action.value as string
        };
    case SET_USER_NAME:
        return {
            ...state,
            userFullName: action.value as string
        };
    default:
      return state;
  }
};
