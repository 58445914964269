import dashboard from './dashboard';
import students from "./students";
import school from "./school";
import admin from "./admin";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, school, students, admin]
};

export default menuItems;
