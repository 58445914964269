import {Reducer} from "redux";

export const SET_MAYBE_ACTIVE_VIRTUAL_KEYBOARDS = '@siteEventsReducer/SET_ACTIVE_STUDENT_ID';
export const SET_TEXT_FIELD_FOCUSED = '@siteEventsReducer/SET_TEXT_FIELD_FOCUSED';
export const SET_MESSAGE_FIELD_DIFF_SIZE = '@siteEventsReducer/SET_MESSAGE_FIELD_DIFF_SIZE';

export type SiteEventStateState = {
    virtualKeyboardIsMaybeVisible: boolean,
    textFieldIsFocused: boolean,
    chatMessageFieldDiffSize: number
}

export const initialState = {
    textFieldIsFocused: false,
    virtualKeyboardIsMaybeVisible: false,
    chatMessageFieldDiffSize: 0
};

export type SiteEventAction = {
    type: typeof SET_MAYBE_ACTIVE_VIRTUAL_KEYBOARDS | typeof SET_TEXT_FIELD_FOCUSED | typeof SET_MESSAGE_FIELD_DIFF_SIZE;
    value: boolean | number
}

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

export const siteEventsReducer : Reducer<SiteEventStateState, SiteEventAction> = (state = initialState, action: SiteEventAction): SiteEventStateState => {
    switch (action.type) {
    case SET_MAYBE_ACTIVE_VIRTUAL_KEYBOARDS:
        return {
            ...state,
            virtualKeyboardIsMaybeVisible: action.value as boolean
        };
    case SET_TEXT_FIELD_FOCUSED:
        return {
            ...state,
            textFieldIsFocused: action.value as boolean
        };
    case SET_MESSAGE_FIELD_DIFF_SIZE:
        if (!Number.isInteger(action.value) || action.value < 0 || action.value > 150) {
            return state;
        }
        return {
            ...state,
            chatMessageFieldDiffSize: action.value as number
        };
    default:
      return state;
  }
};
