import { buildArrSlice, createGenericAsyncThunk } from '../../generic';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import { API_URL } from '../../../config';

export type User = {
  id: string;
  firstName: string;
  familyName: string;
  facilityId: number;
  email: string;
  userRole: string;
  active: boolean;
  updatedAt: string;
};

export const createFacilityUser = createGenericAsyncThunk<User, User>(
  'users/create',
  async (user, { extra, rejectWithValue }) => {
    const response = await extra.ajax['post'](`${API_URL}/api/v1/users`, user);

    const data: User = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);

export const deleteFacilityUser = createGenericAsyncThunk<void, User>(
  'users/delete',
  async ( user, { extra, rejectWithValue }) => {
    const response = await extra.ajax.delete(`${API_URL}/api/v1/users/${user.id}`);
    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, await response.json()));
    }
  }
);

export const updateFacilityUser = createGenericAsyncThunk<User, User>(
  'users/update',
  async (user, { rejectWithValue, extra }) => {
    const response = await extra.ajax['put'](`${API_URL}/api/v1/users/${user.id}`, user);

    const data: User = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);
export const fetchFacilityUsers = createGenericAsyncThunk<User[], {facilityId: number}>(
  'users/fetchFacilityUsers',
  async (facilityId, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(`${API_URL}/api/v1/users?facilityId=${facilityId.facilityId}`);
    const data: User[] = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);

const userFetchSlice = buildArrSlice<User[], {facilityId: number}>(
  'userFetchSlice', fetchFacilityUsers.fulfilled
);
export const usersReducer = userFetchSlice.reducer;
export const selectUsers = createSelector([(state: RootState) => state.users], (student) => student.data);

