import reducer from './reducer';
import { configureStore } from '@reduxjs/toolkit';
import AjaxService from '../services/Ajax';
import { ProcessRejectedValue } from './generic';

export const processRejectedValue: ProcessRejectedValue = (response, data) => {
  return {
    response: data,
    statusCode: response.status
  };
};

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: {
          ajax: AjaxService,
          processRejectedValue
        }
      }
    })
});

export type RootDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export { store };
