import { createGenericAsyncThunk, createGenericSlice } from '../../generic';
import { FacilityEntity, GenericState, UniqueIdentifier } from '../../../types';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import { API_URL } from '../../../config';

export type FacilityGroup = {
  id: number;
  name: string;
  start: string;
  createdAt: string;
  updatedAt: string;
};

export const createFacilityFacilityGroup = createGenericAsyncThunk<FacilityGroup, FacilityEntity<FacilityGroup>>(
  'facilityGroups/createFacilityFacilityGroup',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax['post'](`${API_URL}/api/v1/facilities/${facilityId}/groups`, value);

    const data: FacilityGroup = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);

export const deleteFacilityFacilityGroup = createGenericAsyncThunk<void, FacilityEntity<FacilityGroup>>(
  'facilityGroups/deleteFacilityFacilityGroup',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.delete(`${API_URL}/api/v1/facilities/${facilityId}/groups/${value.id}`);
    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, await response.json()));
    }
  }
);

export const updateFacilityFacilityGroup = createGenericAsyncThunk<FacilityGroup, FacilityEntity<FacilityGroup>>(
  'facilityGroups/updateFacilityFacilityGroup',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax['put'](`${API_URL}/api/v1/facilities/${facilityId}/groups/${value.id}`, value);

    const data: FacilityGroup = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);
export const fetchFacilityFacilityGroups = createGenericAsyncThunk<FacilityGroup[], UniqueIdentifier>(
  'facilityGroups/fetchFacilityFacilityGroups',
  async (facilityId, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(`${API_URL}/api/v1/facilities/${facilityId}/groups`);
    const data: FacilityGroup[] = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);

const facilityGroupSlice = createGenericSlice({
  name: 'facilityGroupsSlice',
  initialState: {
    status: 'idle',
    data: [],
    loaded: false
  } as GenericState<FacilityGroup[]>,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFacilityFacilityGroups.fulfilled, (state, action) => {
      /*
       * Override default behaviour
       */

      state.loaded = true;
      state.status = 'succeeded';
      // @ts-ignore
      state.data = action.payload;
      delete state.error;
    });
  }
});

export const { start, succeeded, failed } = facilityGroupSlice.actions;

export const selectFacilityGroups = createSelector([(state: RootState) => state.facilityGroups], (facilityGroups) => facilityGroups.data);

export const facilityGroupReducer = facilityGroupSlice.reducer;
