import { keyframes } from '@mui/system';
import {Box} from "@mui/material";


export const Loader = () => {
  const rotation = keyframes`
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
`;
  return (
    <Box
      component="img"
      sx={{
        margin: 'auto',
        display: 'inline-block',
        position: 'absolute',
        filter: 'grayscale(70%)',
        right: '50%',
        top: '50%',
        width: '90px',
        height: '90px',
        marginTop: '-45px',
        marginRight: '-45px',
        animation: `${rotation} 2s infinite linear`
      }}

      src={'/compass-logo.webp'}
      alt="Compass"
    />
  )
}
