// assets
import { IconBottle, IconBrandChrome, IconBrandSuperhuman, IconHelp, IconMoodKid, IconLockAccess, IconUsers} from "@tabler/icons-react";
import { MenuItem } from '../types';

// constant
const icons = {IconUsers, IconLockAccess, IconMoodKid,IconBrandChrome, IconHelp, IconBottle, IconBrandSuperhuman };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const students = {
  id: 'students',
  title: 'Students',
  type: 'group',
  children: [
    {
      id: 'student',
      title: 'Students',
      type: 'item',
      url: '/students',
      icon: icons.IconMoodKid,
      breadcrumbs: false,
      roles: ['OWNER', 'TEACHER']
    },
    {
      id: 'parent',
      title: 'Parents & Access',
      type: 'item',
      url: '/parents',
      icon: icons.IconLockAccess,
      breadcrumbs: false,
      roles: ['OWNER']
    },
    {
      id: 'group',
      title: 'Student groups',
      type: 'item',
      url: '/group',
      icon: icons.IconUsers,
      breadcrumbs: false,
      roles: ['OWNER']
    },
    {
      id: 'student-type',
      title: 'Student Types',
      type: 'item',
      url: '/student-type',
      icon: icons.IconBrandChrome,
      breadcrumbs: false,
      roles: ['OWNER']
    },
  ]
} as MenuItem;

export default students;
