import { createGenericAsyncThunk, createGenericSlice } from '../../generic';
import { FacilityEntity, GenericState, UniqueIdentifier } from '../../../types';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import { API_URL } from '../../../config';

export type ExtraClass = {
  id: number;
  namePL: string;
  nameEN: string;
  contactPerson: string;
  external: boolean;
  phone: string;
  email: string;
  parentsPayDirectly: boolean;
};

export const createFacilityExtraClass = createGenericAsyncThunk<ExtraClass, FacilityEntity<ExtraClass>>(
  'extraClasses/createFacilityExtraClass',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax['post'](`${API_URL}/api/v1/facilities/${facilityId}/extra-classes`, value);

    const data: ExtraClass = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);

export const deleteFacilityExtraClass = createGenericAsyncThunk<void, FacilityEntity<ExtraClass>>(
  'extraClasses/deleteFacilityExtraClass',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.delete(`${API_URL}/api/v1/facilities/${facilityId}/extra-classes/${value.id}`);
    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, await response.json()));
    }
  }
);

export const updateFacilityExtraClass = createGenericAsyncThunk<ExtraClass, FacilityEntity<ExtraClass>>(
  'extraClasses/updateFacilityExtraClass',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax['put'](`${API_URL}/api/v1/facilities/${facilityId}/extra-classes/${value.id}`, value);

    const data: ExtraClass = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);

export const fetchFacilityExtraClass = createGenericAsyncThunk<ExtraClass, FacilityEntity<number>>(
  'extraClasses/getExtraClass',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(`${API_URL}/api/v1/facilities/${facilityId}/extra-classes/${value}`);
    const data: ExtraClass = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }
    return data;
  }
);
export const fetchFacilityExtraClasses = createGenericAsyncThunk<ExtraClass[], UniqueIdentifier>(
  'extraClasses/fetchFacilityExtraClasss',
  async (facilityId, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(`${API_URL}/api/v1/facilities/${facilityId}/extra-classes`);
    const data: ExtraClass[] = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);

const extraClassSlice = createGenericSlice({
  name: 'extraClassSlice',
  initialState: {
    status: 'idle',
    data: {},
    loaded: false
  } as GenericState<ExtraClass>,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFacilityExtraClass.fulfilled, (state, action) => {
      state.loaded = true;
      state.status = 'succeeded';
      // @ts-ignore
      state.data = action.payload;
      delete state.error;
    });
  }
});

const extraClassesSlice = createGenericSlice({
  name: 'extraClassesSlice',
  initialState: {
    status: 'idle',
    data: [],
    loaded: false
  } as GenericState<ExtraClass[]>,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFacilityExtraClasses.fulfilled, (state, action) => {
      /*
       * Override default behaviour
       */

      state.loaded = true;
      state.status = 'succeeded';
      // @ts-ignore
      state.data = action.payload;
      delete state.error;
    });
  }
});

export const { start, succeeded, failed } = extraClassesSlice.actions;

export const selectExtraClasses = createSelector([(state: RootState) => state.extraClasses], (extraClasses) => extraClasses.data);

export const selectExtraClass = createSelector([(state: RootState) => state.extraClass], (extraClasses) => extraClasses.data);

export const extraClassesReducer = extraClassesSlice.reducer;
export const extraClassReducer = extraClassSlice.reducer;
