import { Box, Stack, Typography, useTheme } from "@mui/material";
import Logo from "../../Logo";
import { Link } from "react-router-dom";

export function SignInHeader() {
    const  theme = useTheme();

    return (
        <Stack textAlign={'center'} justifyContent={'center'}>
          <Box sx={{ p: 3 }} >
            <Link to="#">
              <Logo />
            </Link>
          </Box>

          <Typography color={theme.palette.secondary.main} gutterBottom sx={{ fontSize: '1.5rem' }} variant={ 'h3' }>
            Hi, Welcome Back
          </Typography>
          <Typography variant="caption" fontSize="16px" color={'#697586'} textAlign={'center' }>
            Enter your credentials to continue
          </Typography>
        </Stack>
    );
}
