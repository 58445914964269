// assets
import { IconUser } from '@tabler/icons-react';
import { MenuItem } from '../types';

// constant
const icons = { IconUser };

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const admin = {
  id: 'admin-item',
  title: "Admin",
  type: 'group',
  children: [
    {
      id: 'users',
      title: 'User management',
      type: 'item',
      url: '/users',
      icon: icons.IconUser,
      breadcrumbs: false,
      roles: ['OWNER']
    }
  ]
} as MenuItem;

export default admin;
