import { buildArrSlice, buildSlice, createGenericAsyncThunk } from "../../generic";
import { FacilityEntity, UniqueIdentifier } from "../../../types";
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import { Student } from "../student";
import { API_URL } from '../../../config';

export type Parent = {
  id: number;
  firstName: string;
  familyName: string;
  address: string;
  preferredLanguage: 'EN' | 'PL';
  phone: string;
  email: string;
  type: 'FATHER' | 'MOTHER';
  authAccountId: string;
  students?: Student[]
};

export const fetchFacilityParents = createGenericAsyncThunk<Parent[], { facilityId: number; facilityGroups: number[] }>(
  'parents/fetchFacilityParents',
  async ({ facilityId, facilityGroups }, { extra, rejectWithValue }) => {
    const params = facilityGroups && facilityGroups.length > 0 ? `?${facilityGroups.map((f) => `groups=${f}`).join('&')}` : '';
    const response = await extra.ajax.get(`${API_URL}/api/v1/facilities/${facilityId}/parents${params}`);
    const data: Parent[] = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }
    return data;
  }
);

export const fetchFacilityParent = createGenericAsyncThunk<Parent, { facilityId: number; parentId: number }>(
  'parents/fetchFacilityParent',
  async ({ facilityId, parentId }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(`${API_URL}/api/v1/facilities/${facilityId}/parents/${parentId}`);
    const data: Parent = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }
    return data;
  }
);

export const updateFacilityParent = createGenericAsyncThunk<Parent, FacilityEntity<Parent>>(
  'parents/updateFacilityParent',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax['put'](`${API_URL}/api/v1/facilities/${facilityId}/parents/${value.id}`, value);
    const data: Parent = await response.json();
    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }
    return data;
  }
)

export const createAccount = createGenericAsyncThunk<void, FacilityEntity<UniqueIdentifier>>(
  'parents/createAccount',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.post(`${API_URL}/api/v1/facilities/${facilityId}/parents/${value}/account`);
    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, await response.json()));
    }
  }
);

export const updateAccount = createGenericAsyncThunk<void, FacilityEntity<UniqueIdentifier>>(
  'parents/updateAccount',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.put(`${API_URL}/api/v1/facilities/${facilityId}/parents/${value}/account`);
    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, await response.json()));
    }
  }
);

export const removeAccount = createGenericAsyncThunk<void, {facilityId: number, parentId: number, force: boolean}>(
  'parents/removeAccount',
  async ({ facilityId, parentId, force }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.delete(`${API_URL}/api/v1/facilities/${facilityId}/parents/${parentId}/account?force=${force}`);
    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, await response.json()));
    }
  }
);

const parentsSlice = buildArrSlice<Parent[], { facilityId: number; facilityGroups: number[] }>('parentsSlice', fetchFacilityParents.fulfilled);
const parentSlice = buildSlice<Parent, { facilityId: number; parentId: number }>('parentSlice', fetchFacilityParent.fulfilled);
export const parentsReducer = parentsSlice.reducer;
export const parentReducer = parentSlice.reducer;

export const selectParents = createSelector([(state: RootState) => state.parents], (parents) => parents.data);
export const selectParent = createSelector([(state: RootState) => state.parent], (parents) => parents.data);
