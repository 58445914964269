import * as React from 'react';
import dayjs, { Dayjs } from 'dayjs';

export const tableValue = (value: string | boolean) => {
  if (typeof value === 'boolean') return value ? 'Yes' : 'No';

  return value;
};
export const disableWeekends = (date: Dayjs) => {
  return date.day() === 0 || date.day() === 6;
};

export const dayJsDay = (day: string | Dayjs): Dayjs => {
  if (typeof day === 'string') {
    return dayjs.utc(day);
  }
  return day;
};

export const tableCustomValue = (
  value: string | boolean,
  row: Record<string, any>,
  map?: (row: Record<string, any>) => string | React.ReactNode
) => {
  if (map) {
    return map(row);
  }

  return tableValue(value);
};

export const prettyMonthDayDate = (d: string) => {
  return dayjs(d).format('MMM D')
}

// export const getByKey = (key: string, obj: object) => {
//   return key.split('.').reduce((o, i) => o[i], obj);
// };

export const YesNoOptions = [
  {
    label: 'Yes',
    value: 'true'
  },
  {
    label: 'No',
    value: 'false'
  }
];

export function daysUntilDate(birthDate: Date, targetDate: Date, annual = true) {
  let nextDate;

  if (annual) {
    nextDate = new Date(targetDate.getFullYear(), birthDate.getMonth(), birthDate.getDate());
    if (nextDate < targetDate) {
      nextDate.setFullYear(targetDate.getFullYear() + 1);
    }
  } else {
    nextDate = targetDate;
  }

  const timeDiff = nextDate.getTime() - targetDate.getTime();
  return Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // Convert from milliseconds to days
}


export const generateRandomString = (length = 10) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  // Loop through the length and randomly pick a character each time
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }

  return result;
}

export const moveItem = <T> (items: T[], fromIndex: number, toIndex: number) : T[] => {
  const updatedItems = [...items];
  const [movedItems] = updatedItems.splice(fromIndex, 1);
  updatedItems.splice(toIndex, 0, movedItems);
  return updatedItems;
};

export const prettyDate = (date: string) => {
  const dayjsObj = dayjs(date);
  if (dayjsObj.isToday()) {
    return dayjsObj.format('HH:mm');
  } else {
    return dayjsObj.format('D MMM')
  }
}

export const isStandaloneMobileApp = () => {
  // @ts-ignore
  return window.navigator.standalone === true || window.matchMedia('(display-mode: standalone)').matches
}

export const getMobileOperatingSystem = () => {
  const userAgent = navigator?.userAgent ?? '';

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return "iOS";
  }

  return "Unknown";
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function hexToRgb(hex: string) {
  // Remove the "#" if present
  hex = hex.replace("#", "");

  // Parse the r, g, b values from the hex code
  let r = parseInt(hex.substring(0, 2), 16);
  let g = parseInt(hex.substring(2, 4), 16);
  let b = parseInt(hex.substring(4, 6), 16);

  return { r, g, b };
}

// Function to calculate the relative luminance
function getLuminance(r: number, g: number, b: number) {
  // Normalize the RGB values to the range [0, 1]
  r /= 255;
  g /= 255;
  b /= 255;

  // Apply the gamma correction (sRGB)
  r = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
  g = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
  b = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);

  // Calculate the luminance
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

// Function to determine whether black or white text will have better contrast
function getTextColorForBackground(hexColor: string) {
  const { r, g, b } = hexToRgb(hexColor);
  const luminance = getLuminance(r, g, b);

  // If luminance is more than 0.5, use black text; otherwise, use white text
  return luminance > 0.5 ? "#393838" : "#FFFFFF";
}

export function stringAvatar(name: string) {
  const bgColour = stringToColor(name);
  const textColor =  getTextColorForBackground(bgColour);
  return {
    sx: {
      fontSize: '0.9rem',
      height: '30px',
      width: '30px',
      bgcolor: stringToColor(name),
      color: textColor
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}
