import { createGenericAsyncThunk, createGenericSlice } from '../../generic';
import { FacilityEntity, GenericState, UniqueIdentifier } from '../../../types';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import { API_URL } from '../../../config';

export type Meal = {
  id: number;
  nameEN: string;
  namePL: string;
  price: string;
  details: string;
  createdAt: string;
  updatedAt: string;
  cateringCompany: object;
};

export type MealDemand = {
  totalStudents: number;
  totalAbsences: number;
  totalCancelledMeals: Record<string, number>;
  totalDemand: Record<string, number>;
};

export const createFacilityMeal = createGenericAsyncThunk<Meal, FacilityEntity<Meal>>(
  'meals/createFacilityMeal',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax['post'](`${API_URL}/api/v1/facilities/${facilityId}/meals`, value);

    const data: Meal = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);

export const deleteFacilityMeal = createGenericAsyncThunk<void, FacilityEntity<Meal>>(
  'meals/deleteFacilityMeal',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.delete(`${API_URL}/api/v1/facilities/${facilityId}/meals/${value.id}`);
    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, await response.json()));
    }
  }
);

export const updateFacilityMeal = createGenericAsyncThunk<Meal, FacilityEntity<Meal>>(
  'meals/updateFacilityMeal',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax['put'](`${API_URL}/api/v1/facilities/${facilityId}/meals/${value.id}`, value);

    const data: Meal = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);
export const fetchFacilityMeals = createGenericAsyncThunk<Meal[], UniqueIdentifier>(
  'meals/fetchFacilityMeals',
  async (facilityId, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(`${API_URL}/api/v1/facilities/${facilityId}/meals`);
    const data: Meal[] = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }
    return data;
  }
);

export const fetchFacilityMealDemand = createGenericAsyncThunk<MealDemand, FacilityEntity<string>>(
  'meals/fetchFacilityDemandMeals',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(`${API_URL}/api/v1/facilities/${facilityId}/meals/students/${value}`);
    const data: MealDemand = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }
    return data;
  }
);

const mealSlice = createGenericSlice({
  name: 'mealsSlice',
  initialState: {
    status: 'idle',
    data: [],
    loaded: false
  } as GenericState<Meal[]>,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFacilityMeals.fulfilled, (state, action) => {
      /*
       * Override default behaviour
       */

      state.loaded = true;
      state.status = 'succeeded';
      // @ts-ignore
      state.data = action.payload;
      delete state.error;
    });
  }
});

export const { start, succeeded, failed } = mealSlice.actions;

export const selectMeals = createSelector([(state: RootState) => state.meals], (meals) => meals.data);

export const mealReducer = mealSlice.reducer;
