import { useRootDispatch, useRootSelector } from '../index';
import { useEffect } from 'react';
import {
  fetchUserPreferences,
  selectUserPreferences
} from '../../store/feature/user';

export function useFetchUserPreferences() {
  const dispatch = useRootDispatch();

  useEffect(() => {
    dispatch(fetchUserPreferences());
  }, [dispatch]);

  return useRootSelector((state) => selectUserPreferences(state));
}
