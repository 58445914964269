import { lazy } from 'react';
import MainLayout from '../layout/MainLayout';
import Loadable from '../ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('../views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('../views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('../views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('../views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('../views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('../views/utilities/TablerIcons')));

// sample page routing
const StudentTypesPage = Loadable(lazy(() => import('../views/pages/student-types')));
const ExtraClassesPage = Loadable(lazy(() => import('../views/pages/extra-classes')));
const HolidaysPage = Loadable(lazy(() => import('../views/pages/holidays')));
const StudentsPage = Loadable(lazy(() => import('../views/pages/students')));
const ParentsPage = Loadable(lazy(() => import('../views/pages/parents')));
const FacilityGroupPage = Loadable(lazy(() => import('../views/pages/facility-group')));
const CateringPage = Loadable(lazy(() => import('../views/pages/catering')));
const CalendarPage = Loadable(lazy(() => import('../views/pages/calendar')));
const MealsPage = Loadable(lazy(() => import('../views/pages/meals')));
const StudentSetupPage = Loadable(lazy(() => import('../views/pages/student-setup')));
const ParentSetupPage = Loadable(lazy(() => import('../views/pages/parent-setup')));
const ExtraClassSetupPage = Loadable(lazy(() => import('../views/pages/extra-class-setup')));
const InvoicesPage = Loadable(lazy(() => import('../views/pages/invoices')));
const MessagesPage = Loadable(lazy(() => import('../views/pages/messages')));
const GalleriesPage = Loadable(lazy(() => import('../views/pages/gallery')));
const CreateGalleryPage = Loadable(lazy(() => import('../views/pages/gallery-send')));
const SendMessagePage = Loadable(lazy(() => import('../views/pages/send-message')));
const ChatPage = Loadable(lazy(() => import('../views/pages/chat')));
const Usersage = Loadable(lazy(() => import('../views/pages/users')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-typography',
          element: <UtilsTypography />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-color',
          element: <UtilsColor />
        }
      ]
    },
    {
      path: 'utils',
      children: [
        {
          path: 'util-shadow',
          element: <UtilsShadow />
        }
      ]
    },
    {
      path: 'icons',
      children: [
        {
          path: 'tabler-icons',
          element: <UtilsTablerIcons />
        }
      ]
    },
    {
      path: 'icons',
      children: [
        {
          path: 'material-icons',
          element: <UtilsMaterialIcons />
        }
      ]
    },
    {
      path: 'calendar',
      element: <CalendarPage />
    },
    {
      path: 'catering',
      element: <CateringPage />
    },
    {
      path: 'meals',
      element: <MealsPage />
    },
    {
      path: 'parents',
      element: <ParentsPage />
    },
    {
      path: 'parents/:parentId',
      element: <ParentSetupPage />
    },
    {
      path: 'invoices',
      element: <InvoicesPage />
    },
    {
      path: 'messages',
      element: <MessagesPage />
    },
    {
      path: 'chat/:chatId',
      element: <ChatPage />
    },
    {
      path: 'chat/',
      element: <ChatPage />
    },
    {
      path: 'messages/send',
      element: <SendMessagePage />
    },
    {
      path: 'galleries',
      element: <GalleriesPage />
    },
    {
      path: 'galleries/create',
      element: <CreateGalleryPage />
    },
    {
      path: 'student-type',
      element: <StudentTypesPage />
    },
    {
      path: 'holidays',
      element: <HolidaysPage />
    },
    {
      path: 'students',
      element: <StudentsPage />
    },
    {
      path: 'students/:studentId',
      element: <StudentSetupPage />
    },
    {
      path: 'group',
      element: <FacilityGroupPage />
    },
    {
      path: 'extra-classes',
      element: <ExtraClassesPage />
    },
    {
      path: 'extra-classes/:classId',
      element: <ExtraClassSetupPage />
    },
    {
      path: 'users',
      element: <Usersage />
    },
  ]
};

export default MainRoutes;
