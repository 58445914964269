import { buildSlice, createGenericAsyncThunk } from '../../generic';
import { FacilityEntity } from '../../../types';
import { API_URL } from '../../../config';

export type StudentAbsenceCreation = {
  startDate: string;
  endDate: string;
  studentId: number;
};

export type StudentMealCancelCreation = {
  startDate: string;
  endDate: string;
  studentId: number;
  meals: { name: string; id: number }[];
};

export type StudentCustomEventCreation = {
  startDate: string;
  endDate: string;
  messagePL: string;
  messageEN: string;
  studentIds: number[];
};

export type StudentAbsenceEventData = {
  '@type': 'absence';
  studentId: number;
};

export type StudentMealCancelEventData = {
  '@type': 'refused-meals';
  studentId: number;
  meals: { name: string; id: number }[];
};

export type StudentCustomEventData = {
  '@type': 'custom';
  messagePL: string;
  messageEN: string;
  studentId: number;
};

export type ExtraClassGroupEventData = {
  '@type': 'extra-class';
  name: string;
  id: string;
  groupId: string;
};

export type CalendarEventFilter = {
  start: string;
  end: string;
  type?: string;
  studentId?: string;
};

export type CalendarEvent = {
  eventGroup: string;
  id: string;
  date: string;
  duration?: number;
  time?: string;
  endTime?: string;
  createdOn: string;
  calendarEventData: StudentCustomEventData | StudentMealCancelEventData | StudentAbsenceEventData | ExtraClassGroupEventData;
};

export const fetchCalendarEvents = createGenericAsyncThunk<CalendarEvent[], FacilityEntity<CalendarEventFilter>>(
  'calendar/fetchCalendarEvents',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(
      `${API_URL}/api/v1/facilities/${facilityId}/calendar-events?${new URLSearchParams(value)}`
    );
    const data: CalendarEvent[] = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }
    return data;
  }
);

export const fetchCalendarExtraClassGroupEventDates = createGenericAsyncThunk<string[], FacilityEntity<number>>(
  'calendar/fetchCalendarExtraClassGroupEventDates',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(
      `${API_URL}/api/v1/facilities/${facilityId}/calendar-events/dates/extra-class-groups/${value}`
    );
    const data: string[] = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }
    return data;
  }
);

export const addCalendarStudentAbsenceEvent = createGenericAsyncThunk<StudentAbsenceCreation, FacilityEntity<StudentAbsenceCreation>>(
  'calendar/addCalendarStudentAbsenceEvent',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.post(`${API_URL}/api/v1/facilities/${facilityId}/calendar-events/absence`, value);
    const data: StudentAbsenceCreation = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);

export const addCalendarMealCancellationEvent = createGenericAsyncThunk<
  StudentMealCancelCreation,
  FacilityEntity<StudentMealCancelCreation>
>('calendar/addCalendarStudentMealCancellationEvent', async ({ facilityId, value }, { extra, rejectWithValue }) => {
  const response = await extra.ajax.post(`${API_URL}/api/v1/facilities/${facilityId}/calendar-events/meal-cancel`, value);
  const data: StudentMealCancelCreation = await response.json();

  if (response.status !== 200) {
    return rejectWithValue(extra.processRejectedValue(response, data));
  }

  return data;
});

export const addCalendarStudentCustomEvent = createGenericAsyncThunk<
  StudentCustomEventCreation,
  FacilityEntity<StudentCustomEventCreation>
>('calendar/addCalendarStudentMealCancellationEvent', async ({ facilityId, value }, { extra, rejectWithValue }) => {
  const response = await extra.ajax.post(`${API_URL}/api/v1/facilities/${facilityId}/calendar-events/custom`, value);
  const data: StudentCustomEventCreation = await response.json();

  if (response.status !== 200) {
    return rejectWithValue(extra.processRejectedValue(response, data));
  }

  return data;
});

export const deleteCalendarEvent = createGenericAsyncThunk<void, FacilityEntity<number>>(
  'calendar/deleteCalendarEvent',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.delete(`${API_URL}/api/v1/facilities/${facilityId}/calendar-events/${value}`);
    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, await response.json()));
    }
  }
);

export const deleteCalendarEventByGroupId = createGenericAsyncThunk<void, FacilityEntity<string>>(
  'calendar/deleteCalendarEventByGroupId',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.delete(`${API_URL}/api/v1/facilities/${facilityId}/calendar-events/group/${value}`);
    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, await response.json()));
    }
  }
);

const calendarDatesSlice = buildSlice<string[], FacilityEntity<number>>(
  'calendarDatesSlice',
  fetchCalendarExtraClassGroupEventDates.fulfilled
);
export const calendarExtraGroupDatesReducer = calendarDatesSlice.reducer;
