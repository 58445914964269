import { createSelector } from '@reduxjs/toolkit';
import { GenericState } from '../../../types';
import { createGenericSlice } from '../../generic';
import { RootState } from '../../index';

const initialState: GenericState<unknown> = {
  status: 'idle',
  data: {},
  loaded: false
};

const formDataSlice = createGenericSlice({
  name: 'formData',
  initialState,
  reducers: {}
});

export function selectFormDataState<T>() {
  return createSelector(
    (state: RootState) => state.formData,
    (formData) => formData as GenericState<T>
  );
}

export const { start, succeeded, failed, reset } = formDataSlice.actions;

export const formDataReducer = formDataSlice.reducer;
