import { createGenericAsyncThunk, createGenericSlice } from '../../generic';
import { FacilityEntity, GenericState, UniqueIdentifier } from '../../../types';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import dayjs from 'dayjs';
import { API_URL } from '../../../config';

export type Holiday = {
  id: number;
  namePL: string;
  nameEN: string;
  type: 'ANNUAL' | 'RANGE';
  dayCare: boolean;
  month: string;
  day: string;
  startDate: string | dayjs.Dayjs;
  endDate: string | undefined | dayjs.Dayjs;
  updatedAt: string;
  createdOn: string;
};

export type SimpleHoliday = {
  date: string;
  name: string;
};

export const createFacilityHoliday = createGenericAsyncThunk<Holiday, FacilityEntity<Holiday>>(
  'holidays/create',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax['post'](`${API_URL}/api/v1/facilities/${facilityId}/holidays`, value);

    const data: Holiday = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);

export const deleteFacilityHoliday = createGenericAsyncThunk<void, FacilityEntity<Holiday>>(
  'holidays/delete',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.delete(`${API_URL}/api/v1/facilities/${facilityId}/holidays/${value.id}`);
    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, await response.json()));
    }
  }
);

export const updateFacilityHoliday = createGenericAsyncThunk<Holiday, FacilityEntity<Holiday>>(
  'holidays/update',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax['put'](`${API_URL}/api/v1/facilities/${facilityId}/holidays/${value.id}`, value);

    const data: Holiday = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);
export const fetchFacilityHolidays = createGenericAsyncThunk<Holiday[], UniqueIdentifier>(
  'studentTypes/fetchFacilityHolidays',
  async (facilityId, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(`${API_URL}/api/v1/facilities/${facilityId}/holidays`);
    const data: Holiday[] = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);

export const fetchFacilitySimpleHolidays = createGenericAsyncThunk<SimpleHoliday[], UniqueIdentifier>(
  'studentTypes/fetchFacilitySimpleHolidays',
  async (facilityId, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(`${API_URL}/api/v1/facilities/${facilityId}/holidays/simple`);
    const data: SimpleHoliday[] = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);

const holidaySlice = createGenericSlice({
  name: 'holidaySlice',
  initialState: {
    status: 'idle',
    data: [],
    loaded: false
  } as GenericState<Holiday[]>,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFacilityHolidays.fulfilled, (state, action) => {
      /*
       * Override default behaviour
       */

      state.loaded = true;
      state.status = 'succeeded';
      // @ts-ignore
      state.data = action.payload;
      delete state.error;
    });
  }
});

const simpleHolidaySlice = createGenericSlice({
  name: 'simpleHolidaySlice',
  initialState: {
    status: 'idle',
    data: [],
    loaded: false
  } as GenericState<SimpleHoliday[]>,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFacilitySimpleHolidays.fulfilled, (state, action) => {
      state.loaded = true;
      state.status = 'succeeded';
      // @ts-ignore
      state.data = action.payload;
      delete state.error;
    });
  }
});

export const { start, succeeded, failed } = holidaySlice.actions;

export const selectHolidays = createSelector([(state: RootState) => state.holidays], (holidays) => holidays.data);

export const selectSimpleHolidays = createSelector([(state: RootState) => state.simpleHolidays], (holidays) => holidays.data);

export const holidayReducer = holidaySlice.reducer;
export const simpleHolidayReducer = simpleHolidaySlice.reducer;
