import { buildSlice, createGenericAsyncThunk } from "../../generic";
import { FacilityEntity } from "../../../types";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../index";
import { Dayjs } from 'dayjs';
import { API_URL } from '../../../config';

export type InvoiceStatus = 'DRAFT' | 'CONFIRMED' | 'ISSUED' | 'PAID';

export type Invoice = {
  id: number;
  reference: string;
  year: number;
  month: number;
  studentId: number;
  items: InvoiceItem[];
  total: number;
  status: InvoiceStatus;
};

export type InvoiceItem = {
  name: string;
  extra: string;
  subTotal: number;
};

export type InvoiceCreationForm = {
  date: Dayjs;
  meals: Record<string, {thisMonthPrice: number, prevMonthPrice: number}>
  force: string
};

export type InvoiceConfiguration = {
  month: number;
  year: number;
  meals: Record<number, {thisMonthPrice: number, prevMonthPrice: number}>
  force: string
};

export type InvoiceFilter = {
  month?: string;
  year?: string;
  statuses?: InvoiceStatus[];
}

export const fetchInvoices = createGenericAsyncThunk<Invoice[], {facilityId: number, filter: InvoiceFilter}>(
  'invoices/fetchInvoices',
  async ({facilityId, filter}, { extra, rejectWithValue }) => {
    const response = await extra.ajax.get(`${API_URL}/api/v1/facilities/${facilityId}/invoices?${new URLSearchParams(filter as Record<string, string>).toString()}`);
    const data: Invoice[] = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);

export const generateInvoices = createGenericAsyncThunk<Invoice, FacilityEntity<InvoiceConfiguration>>(
  'calendar/addCalendarStudentAbsenceEvent',
  async ({ facilityId,value }, { extra, rejectWithValue }) => {
    const response = await extra.ajax.post(`${API_URL}/api/v1/facilities/${facilityId}/invoices`, value);
    const data: Invoice = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);

export const updateInvoice = createGenericAsyncThunk<Invoice, FacilityEntity<Invoice>>(
  'calendar/addCalendarStudentAbsenceEvent',
  async ({ facilityId, value }, { extra, rejectWithValue }) => {
    value.items = value.items.filter((i) => i.subTotal > 0 && i.name !== '');
    const response = await extra.ajax.put(`${API_URL}/api/v1/facilities/${facilityId}/invoices/${value.id}`, value);
    const data: Invoice = await response.json();

    if (response.status !== 200) {
      return rejectWithValue(extra.processRejectedValue(response, data));
    }

    return data;
  }
);

export const fetchInvoicesSlice = buildSlice<Invoice[], { facilityId: number }>(
  'invoicesSlice',
  fetchInvoices.fulfilled
);

export const invoicesReducer = fetchInvoicesSlice.reducer;


export const selectInvoices = createSelector([(state: RootState) => state.invoices], (a) => a.data);
